.center {
  text-align: center;
}

.h1,
.h2,
.h3,
.h4 {
  letter-spacing: 2px;
  line-height: 1.2em;
}

.h1 {
  font-size: 36px;

  @include media-breakpoint-up(lg) {
    font-size: 60px;
  }
}

.h2 {
  font-size: 36px;

  @include media-breakpoint-up(lg) {
    font-size: 48px;
  }
}

.h3 {
  font-size: 30px;

  @include media-breakpoint-up(lg) {
    font-size: 50px;
  }
}

.h4 {
  font-size: 25px;

  @include media-breakpoint-up(lg) {
    font-size: 40px;
  }
}

