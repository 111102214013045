@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.navigationSpacer {
  height: $header-height;

  &.ribbonActive {
    height: $header-height + $navigation-ribbon-min-height;
  }

  @include media-breakpoint-down(lgg) {
    height: $header-height-mobile;

    &.ribbonActive {
      height: $header-height-mobile + $navigation-ribbon-min-height;
    }
  }
}

.blurContainer{
  &.blur {
    @include media-breakpoint-down(lgg) {
      @include blurNavigation();
    }
  }
}
