@import '../../styles/variables.scss';
@import '../../styles/themes.scss';
@import '../../styles/mixins.scss';
@import '../../styles/world/world-custom.scss';
@import '../../styles/world/world-typography.scss';

$text-color: $white-floral;
$sub-text-color: #dfdbd9;

.videoBackground {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;

  video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.descriptionText {
  max-width: 662px;
  padding: 0 30px;

  & > section[data-article-gambit='true'] {
    font-size: 16px;
    line-height: 24px;

    & span > span > span {
      position: relative;
      bottom: -5px;
    }
  }
}

.scrollIndicator {
  position: relative;

  & > div {
    margin: 0;
  }
}

.shallow {
  position: relative;
  margin: 0;

  .content {
    position: relative;
    height: auto;

    & > * {
      position: relative;
    }
  }

  .h1 {
    font-size: 26px;
    line-height: 34px;
    margin: 30px 0;

    @include media-breakpoint-up(md) {
      font-size: 45px;
      line-height: 55px;
      margin: 30px 0;
    }
  }

  .descriptionText {
    font-size: 14px;
    line-height: 1.43;
    white-space: pre-line;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 25px;
    }
  }
}

.descriptionTitle {
  color: $white;
  font-size: 26px;
  line-height: 31.72px;
  font-family: $sofiapro;
  font-weight: 400;
  margin: 0 30px 25px;
}

.wrapper {
  --background-image: 'none';
  width: 100vw;
  max-width: 100%;
  background-image: var(--background-image);
  background-size: cover;
  background-position: top center;
  position: relative;
  text-align: center;
  background-color: $black;

  @include media-breakpoint-down(sm) {
    background-image: var(--background-image-mobile);
  }

  &.noImage {
    background-color: transparent;
  }

  .content {
    justify-content: flex-start;
    padding: 120px 0 200px;
    min-height: 0px;

    @include media-breakpoint-up(lg) {
      padding-top: 80px;
    }

    &.modalHero {
      height: auto;
      padding-bottom: 0;
    }
  }

  :focus,
  :active {
    outline: 0;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: var(--background-image-2x);
    @include media-breakpoint-down(sm) {
      background-image: var(--background-image-mobile);
    }
  }

  @include media-breakpoint-up(lg) {
    background-image: var(--background-image-2x);
  }

  :global {
    .headroom {
      top: auto;
      left: auto;
      right: auto;
    }

    .headroom--unfixed {
      position: relative;
      transition: opacity 0.5s;
      -webkit-transition: opacity 0.5s;
      opacity: 1;
    }

    .headroom--scrolled {
      opacity: 0;
    }

    .headroom--unpinned {
      position: relative;
      opacity: 0;
      transition: opacity 0.5s;
      -webkit-transition: opacity 0.5s;
    }

    .headroom--pinned {
      position: relative;
    }
  }

  .logo {
    width: 100px;
    height: 100px;
    position: relative;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      width: 150px;
      height: 150px;
    }
  }
}

.deep {
  &.wrapper {
    .content {
      padding: 42px 0 40px;

      @include media-breakpoint-up(lg) {
        padding: 40px 0 48px;
      }
    }
  }

  .subtitle {
    margin: 0 30px;
  }

  .descriptionTitle {
    margin: 4px 30px 24px;

    @include media-breakpoint-up(lg) {
      margin: 18px 30px 32px;
    }
  }

  .h1 {
    font-weight: 400;
    margin: 2px 30px 4px;

    @include media-breakpoint-up(lg) {
      margin: 7px 30px 18px;
    }
  }
}

// overwriting inline button margins
.cta {
  margin-top: 18px !important;

  @include media-breakpoint-up(lg) {
    margin-top: 27px !important;
  }
}

.extra-shallow {
  position: relative;
  margin: 0;
  padding: 34px;

  @include media-breakpoint-up(lg) {
    padding: 64px 0;
  }

  .content {
    position: relative;
    height: auto;
    padding: 0;

    @include media-breakpoint-up(lg) {
      padding: 0;
    }

    & > * {
      position: relative;
    }
  }

  .h1 {
    font-size: 24px;
    line-height: 32px;
    margin: 30px 0;

    @include media-breakpoint-up(lg) {
      font-size: 46px;
      line-height: 54px;
      margin: 0;
    }
  }

  .descriptionText {
    display: none;
  }
}

.hasVideo {
  &.wrapper {
    .content {
      padding: 40px 0 36px;

      @include media-breakpoint-up(lg) {
        padding: 50px 0 72px;
      }
    }
  
    .h1 {
      @include media-breakpoint-up(md) {
        margin: 24px 0 20px;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 893px;
  min-height: 740px;
  position: relative;

  &.large,
  &.tall {
    padding: 0 20px;
    height: auto;
    justify-content: initial;
    max-height: none;
    min-height: 0;
  }

  &.small {
    min-height: 0;
    height: 700px;
  }

  @include media-breakpoint-up(lg) {
    padding: 0px;

    &.large {
      background-size: cover;
    }
  }
}

.eyebrow {
  font-size: 12px;
  line-height: 19.52px;
  font-family: $sofiapro;
  text-transform: uppercase;
  letter-spacing: 1.85px;
  color: $sub-text-color;
  margin: 0 30px;

  @include media-breakpoint-up(lg) {
    font-size: 14px;
    line-height: 21.96px;
  }
}

.h1 {
  max-width: 595px;
  font-family: $harrybeastregular;
  font-weight: 400;
  letter-spacing: 0;
  color: $text-color;
  font-size: 32px;
  line-height: normal;
  margin-top: 10px;
  padding: 0 10px;

  .small & {
    font-size: 35px;
  }

  .large & {
    font-size: 35px;
  }

  .tall & {
    font-size: 28px;
    line-height: 32px;
  }

  &.gold {
    color: $gold-mid;
  }

  @include media-breakpoint-up(md) {
    font-size: 36px;
    line-height: 42px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 54px;
    line-height: 56px;

    .large & {
      font-size: 100px;
      line-height: normal;
      max-width: none;
      margin-bottom: 36px;
    }

    .tall & {
      font-size: 48px;
      line-height: 52px;
      max-width: none;
    }
  }
}

.subtitle {
  @include sofiaRegular();
  max-width: 595px;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  color: $sub-text-color;
  padding: 0 10px;

  .large & {
    font-size: 18px;
    line-height: 1.33;
  }

  .tall & {
    font-size: 16px;
    line-height: 24px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 22px;
    line-height: 32px;
    white-space: pre-line;

    .large & {
      font-size: 22px;
      line-height: 32px;
    }

    .tall & {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

// themes
[data-themed='true'] .wrapper {
  .eyebrow {
    color: var($--theme-text-color);
    font-weight: bold;
  }

  .h1 {
    color: var($--theme-text-color);
  }

  .descriptionTitle {
    color: var($--theme-text-color);
  }

  .descriptionText {
    color: var($--theme-text-color);

    & > section[data-article-gambit='true'] {
      color: var($--theme-text-color);

      > span > span > span {
        background-image: linear-gradient(
            -45deg,
            transparent 0% 48%,
            rgba(255, 255, 255, 0.8) 50%,
            transparent 52% 100%
          ),
          linear-gradient(
            45deg,
            var($--theme-hero-drop-cap-color),
            var($--theme-hero-drop-cap-color),
            var($--theme-hero-drop-cap-color)
          );
      }
    }
  }
}
