/* stylelint-disable pageArea, goldShine */
.p,
p.darkShineSilver {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.33;

  @include media-breakpoint-up(md) {
    font-size: 30px;
  }
}

.darkShineSilver,
.lightSilverShine,
.goldShine {
  line-height: 1.33;
  letter-spacing: normal;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.darkShineSilver {
  background-image: linear-gradient(139deg, #d5d5d5, #8f8c86);
}

.lightSilverShine {
  background-image: linear-gradient(281deg, #999, #fff 46%, #999);
}

.goldShine {
  background-image: linear-gradient(96deg, #e7da9a, #cdb373 41%, #996e2d);
}

.eyeBrow {
  font-family: $harrybeastregular;
  letter-spacing: 2px;
  text-align: center;
  font-size: 10px;
  margin-top: 50px;
  color: $white;
  text-transform: uppercase;
}

.pageArea {
  padding: 40px 0 0 0;
}
/* stylelint-enable */
