@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $ww-black;
  flex-direction: column;
  .rootInner {
    flex: 1;
  }

  img {
    max-width: 595px;
    margin: 150px auto 10px;
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin: 170px auto 40px;
    }
  }

  .buttonRow {
    margin: 50px 0 0;

    @include media-breakpoint-up(lg) {
      margin: 50px 0;
    }
  }
}

.text {
  text-align: center;
  font-family: $sofiapro;
  font-size: 24px;
}

.imageSource {
  width: 80%;
  float: left;
  padding: 20px;
}
