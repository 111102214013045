$articleBreakPoint: 924px;
@import '../../../../styles/variables.scss';

.sourceDetails {
  color: $grey-caption;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  margin-bottom: 25px;
  font-family: $sofiapro;
  padding: 0 27.42px;
}

.heroImageSource {
  position: relative;
  @media only screen and (min-width: $articleBreakPoint) {
    margin: auto;
    margin-bottom: 25px;
    width: 914px;
    box-sizing: border-box;
  }
}
